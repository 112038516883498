.recording-controls {
  .button {
    margin: 0.5rem;
  }
  .timer {
    font-size: 2rem;
    margin-top: 0.5rem;
    font-weight: 100;
  }

  .button[disabled] {
    opacity: 0.2 !important;
  }
}

.submission-notification {
  font-size: 2rem;
  .fas {
    margin-right: 0.5rem;
  }
}
