.prompts-page {
  .prompt-link {
    display: block;
    font-size: 1.8rem;
    font-weight: 100;
    margin: 1rem;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.04);
    color: black;
    b {
      font-weight: 400;
    }
  }
  .prompt-link:hover {
    background-color: rgba(0, 0, 0, 0.08);
    cursor: pointer;
  }
  .no-prompt-label {
    font-weight: 400;
  }
}
