.loading-page {
  display: flex;
  flex-direction: "row";
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;

  .logo-image {
    width: 8rem;
    height: 8rem;
  }
}
