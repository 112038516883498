.topic-title {
  text-align: center;
  background-size: cover;
  .title {
    font-family: "Roboto", "Helvetica", sans-serif;
    font-size: 4rem;
    font-weight: 100;
    color: white;
  }
}
