.recorder-page {
  text-align: center;
  .return-link-container {
    text-align: left;
  }
  .prompt,
  .tips {
    font-weight: 100;
    font-size: 1.8rem;
    margin: 1rem;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.04);
    color: black;
    b {
      font-weight: 400;
    }
  }
}
