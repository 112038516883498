.landing-hero {
  display: flex;
  flex-direction: row;
  background-color: #79a5f2;
  .hero-body {
    background-color: #79a5f2;
  }
  .hero-logo {
    height: 6rem;
    margin-right: 1rem;
  }

  h1,
  h2 {
    color: white;
  }

  h1 {
    display: flex;
    flex-wrap: wrap;
    font-size: 6rem;
    font-weight: 100;
    align-items: center;
  }

  h2 {
    font-size: 3rem;
    font-weight: 100;
  }

  .topics-graphic {
    display: flex;
    flex: 1;
    flex-direction: column;
    .topic-strip {
      flex: 1;
    }
  }
}

.landing-cta-section {
  background-color: white;
  .landing-cta {
    font-size: 3rem;
    font-weight: 100;
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 769px), print {
  .hero.landing-hero.is-large .hero-body {
    // padding: 10rem 1.5rem;
    padding: 0;
    height: 32rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;

    .container {
      flex-grow: 0;
    }
  }
}

@media screen and (max-width: 600px), print {
  .hero.landing-hero.is-large .hero-body {
    h1 {
      font-size: 4rem;
    }

    h2 {
      font-size: 2rem;
    }
  }
  .landing-cta-section {
    .landing-cta {
      font-size: 2rem;
    }
  }
}
